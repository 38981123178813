/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    pre: "pre",
    code: "code",
    h2: "h2",
    a: "a"
  }, _provideComponents(), props.components), {Editor} = _components;
  if (!Editor) _missingMdxReference("Editor", true);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "The Toolbar module allow users to easily format Quill's contents."), "\n", React.createElement("div", {
    className: "quill-wrapper"
  }, React.createElement("div", {
    id: "toolbar-toolbar",
    className: "toolbar"
  }, React.createElement("span", {
    className: "ql-formats"
  }, React.createElement("select", {
    className: "ql-font"
  }, React.createElement("option", {
    selected: true
  }), React.createElement("option", {
    value: "serif"
  }), React.createElement("option", {
    value: "monospace"
  })), React.createElement("select", {
    className: "ql-size"
  }, React.createElement("option", {
    value: "small"
  }), React.createElement("option", {
    selected: true
  }), React.createElement("option", {
    value: "large"
  }), React.createElement("option", {
    value: "huge"
  }))), React.createElement("span", {
    className: "ql-formats"
  }, React.createElement("button", {
    className: "ql-bold"
  }), React.createElement("button", {
    className: "ql-italic"
  }), React.createElement("button", {
    className: "ql-underline"
  }), React.createElement("button", {
    className: "ql-strike"
  })), React.createElement("span", {
    className: "ql-formats"
  }, React.createElement("select", {
    className: "ql-color"
  }), React.createElement("select", {
    className: "ql-background"
  })), React.createElement("span", {
    className: "ql-formats"
  }, React.createElement("button", {
    className: "ql-list",
    value: "ordered"
  }), React.createElement("button", {
    className: "ql-list",
    value: "bullet"
  }), React.createElement("select", {
    className: "ql-align"
  }, React.createElement("option", {
    selected: true
  }), React.createElement("option", {
    value: "center"
  }), React.createElement("option", {
    value: "right"
  }), React.createElement("option", {
    value: "justify"
  }))), React.createElement("span", {
    className: "ql-formats"
  }, React.createElement("button", {
    className: "ql-link"
  }), React.createElement("button", {
    className: "ql-image"
  }))), React.createElement(Editor, {
    style: {
      display: 'none'
    },
    config: {
      modules: {
        toolbar: {
          container: '#toolbar-toolbar'
        }
      },
      theme: 'snow'
    }
  })), "\n", React.createElement(_components.p, null, "It can be configured with a custom container and handlers."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-javascript"
  }, "const quill = new Quill('#editor', {\n  modules: {\n    toolbar: {\n      container: '#toolbar', // Selector for toolbar container\n      handlers: {\n        bold: customBoldHandler\n      }\n    }\n  }\n});\n")), "\n", React.createElement(_components.p, null, "Because the ", React.createElement(_components.code, null, "container"), " option is so common, a top level shorthand is also allowed."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-javascript"
  }, "const quill = new Quill('#editor', {\n  modules: {\n    // Equivalent to { toolbar: { container: '#toolbar' }}\n    toolbar: '#toolbar'\n  }\n});\n")), "\n", React.createElement(_components.h2, null, "Container"), "\n", React.createElement(_components.p, null, "Toolbar controls can either be specified by a simple array of format names or a custom HTML container."), "\n", React.createElement(_components.p, null, "To begin with the simpler array option:"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-javascript"
  }, "const toolbarOptions = ['bold', 'italic', 'underline', 'strike'];\n\nconst quill = new Quill('#editor', {\n  modules: {\n    toolbar: toolbarOptions\n  }\n});\n")), "\n", React.createElement(_components.p, null, "Controls can also be grouped by one level of nesting an array. This will wrap controls in a ", React.createElement(_components.code, null, "<span>"), " with class name ", React.createElement(_components.code, null, "ql-formats"), ", providing structure for themes to utilize. For example ", React.createElement(_components.a, {
    href: "/docs/themes/#snow/"
  }, "Snow"), " adds extra spacing between control groups."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-javascript"
  }, "const toolbarOptions = [['bold', 'italic'], ['link', 'image']];\n")), "\n", React.createElement(_components.p, null, "Buttons with custom values can be specified with an Object with the name of the format as its only key."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-javascript"
  }, "const toolbarOptions = [{ header: '3' }];\n")), "\n", React.createElement(_components.p, null, "Dropdowns are similarly specified by an Object, but with an array of possible values. CSS is used to control the visual labels for dropdown options."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-javascript"
  }, "// Note false, not 'normal', is the correct value\n// quill.format('size', false) removes the format,\n// allowing default styling to work\nconst toolbarOptions = [\n  { size: [ 'small', false, 'large', 'huge' ]}\n];\n")), "\n", React.createElement(_components.p, null, "Note ", React.createElement(_components.a, {
    href: "/docs/themes/"
  }, "Themes"), " may also specify default values for dropdowns. For example, ", React.createElement(_components.a, {
    href: "/docs/themes/#snow/"
  }, "Snow"), " provides a default list of 35 colors for the ", React.createElement(_components.code, null, "color"), " and ", React.createElement(_components.code, null, "background"), " formats, if set to an empty array."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-javascript"
  }, "const toolbarOptions = [\n  ['bold', 'italic', 'underline', 'strike'],        // toggled buttons\n  ['blockquote', 'code-block'],\n\n  [{ 'header': 1 }, { 'header': 2 }],               // custom button values\n  [{ 'list': 'ordered'}, { 'list': 'bullet' }],\n  [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript\n  [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent\n  [{ 'direction': 'rtl' }],                         // text direction\n\n  [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown\n  [{ 'header': [1, 2, 3, 4, 5, 6, false] }],\n\n  [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme\n  [{ 'font': [] }],\n  [{ 'align': [] }],\n\n  ['clean']                                         // remove formatting button\n];\n\nconst quill = new Quill('#editor', {\n  modules: {\n    toolbar: toolbarOptions\n  },\n  theme: 'snow'\n});\n")), "\n", React.createElement(_components.p, null, "For use cases requiring even more customization, you can manually create a toolbar in HTML, and pass the DOM element or selector into Quill. The ", React.createElement(_components.code, null, "ql-toolbar"), " class will be added to the toolbar container and Quill attach appropriate handlers to ", React.createElement(_components.code, null, "<button>"), " and ", React.createElement(_components.code, null, "<select>"), " elements with a class name in the form ", React.createElement(_components.code, null, "ql-${format}"), ". Buttons element may optionally have a custom ", React.createElement(_components.code, null, "value"), " attribute."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-html"
  }, "<!-- Create toolbar container -->\n<div id=\"toolbar\">\n  <!-- Add font size dropdown -->\n  <select class=\"ql-size\">\n    <option value=\"small\"></option>\n    <!-- Note a missing, thus falsy value, is used to reset to default -->\n    <option selected></option>\n    <option value=\"large\"></option>\n    <option value=\"huge\"></option>\n  </select>\n  <!-- Add a bold button -->\n  <button class=\"ql-bold\"></button>\n  <!-- Add subscript and superscript buttons -->\n  <button class=\"ql-script\" value=\"sub\"></button>\n  <button class=\"ql-script\" value=\"super\"></button>\n</div>\n<div id=\"editor\"></div>\n\n<!-- Initialize editor with toolbar -->\n<script>\n  const quill = new Quill('#editor', {\n    modules: {\n      toolbar: '#toolbar'\n    }\n  });\n</script>\n")), "\n", React.createElement(_components.p, null, "Note by supplying your own HTML element, Quill searches for particular input elements, but your own inputs that have nothing to do with Quill can still be added and styled and coexist."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-html"
  }, "<div id=\"toolbar\">\n  <!-- Add buttons as you would before -->\n  <button class=\"ql-bold\"></button>\n  <button class=\"ql-italic\"></button>\n\n  <!-- But you can also add your own -->\n  <button id=\"custom-button\"></button>\n</div>\n<div id=\"editor\"></div>\n\n<script>\n  const quill = new Quill('#editor', {\n    modules: {\n      toolbar: '#toolbar',\n    },\n  });\n\n  const customButton = document.querySelector('#custom-button');\n  customButton.addEventListener('click', function () {\n    console.log('Clicked!');\n  });\n</script>\n")), "\n", React.createElement(_components.h2, null, "Handlers"), "\n", React.createElement(_components.p, null, "The toolbar controls by default applies and removes formatting, but you can also overwrite this with custom handlers, for example in order to show external UI."), "\n", React.createElement(_components.p, null, "Handler functions will be bound to the toolbar (so using ", React.createElement(_components.code, null, "this"), " will refer to the toolbar instance) and passed the ", React.createElement(_components.code, null, "value"), " attribute of the input if the corresponding format is inactive, and ", React.createElement(_components.code, null, "false"), " otherwise. Adding a custom handler will overwrite the default toolbar and theme behavior."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-javascript"
  }, "const toolbarOptions = {\n  handlers: {\n    // handlers object will be merged with default handlers object\n    link: function (value) {\n      if (value) {\n        const href = prompt('Enter the URL');\n        this.quill.format('link', href);\n      } else {\n        this.quill.format('link', false);\n      }\n    }\n  }\n};\n\nconst quill = new Quill('#editor', {\n  modules: {\n    toolbar: toolbarOptions\n  }\n});\n\n// Handlers can also be added post initialization\nconst toolbar = quill.getModule('toolbar');\ntoolbar.addHandler('image', showImageUI);\n")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
